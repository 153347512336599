var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vertical-center", attrs: { autocomplete: "on" } },
    [
      _c("transition", { attrs: { appear: "", name: "fade" } }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                !_vm.doneState
                  ? _c(
                      "form",
                      {
                        staticClass: "form-centered",
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.requestReset.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _c("h5", { staticClass: "mb-3" }, [
                          _vm._v("RESET PASSWORD REQUEST")
                        ]),
                        _vm._v(" "),
                        _vm.message
                          ? _c("small", { class: [_vm.messageClass, "mb-3"] }, [
                              _vm._v(_vm._s(_vm.message.value))
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.formIsPopulated
                          ? _c("small", { staticClass: "text-success mb-3" }, [
                              _vm._v("Please enter your email address")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-group mb-3" }, [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: "email_address" }
                            },
                            [_vm._v("Enter your email address")]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "input-group-text",
                              attrs: { id: "addon-email" }
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "at"] }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.email_address,
                                expression: "form.email_address"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "email",
                              id: "email_address",
                              placeholder: "Enter your email address",
                              "aria-describedby": "addon-email",
                              autofocus: "",
                              disabled: _vm.formIsProcessing,
                              autocomplete: "username"
                            },
                            domProps: { value: _vm.form.email_address },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.form,
                                  "email_address",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-primary btn-lg btn-block mb-3",
                            attrs: {
                              type: "submit",
                              id: "requestReset",
                              disabled:
                                !this.formIsPopulated || this.formIsProcessing
                            }
                          },
                          [
                            _vm._v("SUBMIT "),
                            _c("font-awesome-icon", {
                              staticClass: "ms-1",
                              attrs: {
                                icon: ["far", "paper-plane"],
                                "fixed-width": ""
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "small",
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: { name: "signin" },
                                  title: "Click to sign in to your user account"
                                }
                              },
                              [_vm._v("Back to sign in")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  : _vm.redirecting
                  ? [
                      _c("p", [
                        _vm._v(
                          "Redirecting in " +
                            _vm._s(_vm.timeLeft) +
                            " seconds..."
                        )
                      ])
                    ]
                  : [
                      _c("p", [
                        _vm._v(
                          "Please check your email for further instructions when resetting your account password.\n            "
                        )
                      ]),
                      _c("p", [
                        _vm._v(
                          "If the message has not arrived then please ensure that you entered the correct email address."
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "You will be automatically redirected to the sign in page in " +
                            _vm._s(_vm.timeLeft) +
                            " seconds."
                        )
                      ])
                    ]
              ],
              2
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }