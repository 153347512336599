<template>

  <div class="vertical-center" autocomplete="on" v-cloak>
    <transition appear name="fade">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <form class="form-centered" @submit.prevent="signIn">
              <!-- Company Logo -->
              <!-- <img src="/images/XXXXXXXX.png" alt="Company" class="img-responsive mb-3"> -->
              <h3 class="mb-3">PLEASE SIGN IN</h3>

              <small :class="[messageClass, 'mb-3']" v-if="message">{{ message.value }}</small>
              <small class="text-success mb-3" v-if="formIsPopulated">Please sign in to access your account</small>

              <!-- Email Field -->
              <div class="input-group mb-3">
                <label for="email_address" class="sr-only">Enter your email address</label>
                <span class="input-group-text" id="addon-email">
                  <font-awesome-icon :icon="['far', 'at']" />
                </span>
                <input type="email" id="email_address" class="form-control" placeholder="Enter your email address" aria-describedby="addon-email" autofocus :disabled="formIsProcessing" v-model="form.email_address" autocomplete="username">
              </div>

              <!-- Password Field -->
              <div class="input-group mb-3">
                <label for="password" class="sr-only">Enter your password</label>
                <span class="input-group-text" id="addon-password">
                  <font-awesome-icon :icon="['far', 'key']" />
                </span>
                <input type="password" id="password" class="form-control" placeholder="Enter your password" aria-describedby="addon-password" :disabled="formIsProcessing" v-model="form.password" autocomplete="current-password">
              </div>

              <!-- Submit Button -->
              <button type="submit" class="btn btn-primary btn-lg btn-block mb-3" id="signIn" :disabled="!this.formIsPopulated || this.formIsProcessing">SIGN IN <font-awesome-icon class="ms-1" :icon="['far', 'sign-in-alt']" fixed-width /></button>

              <!-- Reset link -->
              <small>
                <router-link :to="{ name: 'reset-password-request' }" title="Click to reset your user account password">Forgot your password?</router-link>
              </small>

            </form>
          </div> <!-- /col-12 -->
        </div> <!-- /row -->
      </div> <!-- /container -->
    </transition>
  </div> <!-- /vertical-center -->

</template>

<script>
  const validate = require('validate.js')

  export default {
    name: 'signin',
    data () {
      return {
        formIsProcessing: false,
        error: false,
        form: {
          email_address: '',
          password: ''
        },
        message: {
          value: 'Enter your credentials to continue',
          isNegative: true
        }
      }
    },
    computed: {
      formIsPopulated() {
        let form = this.form
        return !this.error && form.email_address.length > 0 && form.password.length > 0 && !validate({from: form.email_address}, { from: { email: true }})
      },
      messageClass() {
        return this.message.isNegative ? 'text-danger' : 'text-success'
      }
    },
    watch: {
      'form.email_address': function (val) {
        if (val.length === 0) {
          this.message.value = 'Email address must not be empty'
          this.message.isNegative = true
        } else if (validate({from: val}, { from: { email: true }})) {
          this.message.value = 'Must be a valid email address'
          this.message.isNegative = true
        } else if (!validate({from: val}, { from: { email: true }}) &&  this.form.password.length === 0) {
          this.message.value = 'Please enter your password to continue'
          this.message.isNegative = true
        } else {
          this.error = false
          this.message.value = null
          this.message.isNegative = false
        }
      },
      'form.password': function (val) {
        if (val.length === 0) {
          this.message.value = 'Password must not be empty'
          this.message.isNegative = true
        } else {
          this.error = false
          this.message.value = null
          this.message.isNegative = false
        }
      }
    },
    methods: {
      signIn() {
        // If the user presses enter on the form inputs it fires the signIn event, make sure the form is populated first
        if (!this.formIsPopulated) return

        this.formIsProcessing = true
        this.error = false
        // Submit a PUT request
        io.socket.put('/signin', this.form, (response, jwres) => {
          // Handle errors
          if (jwres.error) {
            // Display the error message
            this.message.value = jwres.body.replace(/[^a-zA-Z ]/g, '')
            this.message.isNegative = true
            // Enable the form inputs
            this.formIsProcessing = false
            // Disable "Please enter your password to continue"
            this.error = true
            // TODO: This should work but having to reable the input as it's currently disabled. Issue for vue in github?
            document.getElementById('signIn').removeAttribute('disabled')
            // Return control to the user
            return
          }
          // Notify the user
          this.notify({ text: 'Signing in to your acccount...' })
          // Prevent the user from using back with .replace
          setTimeout(() => { window.location.replace('/') }, 1000)
        })
      }
    },
  }
</script>

<style lang="scss">

</style>