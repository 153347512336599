//  ┌─┐┌─┐┬┬  ┌─┐  ┬┌─┐
//  └─┐├─┤││  └─┐  ││ │
//  └─┘┴ ┴┴┴─┘└─┘  ┴└─┘

import * as socketIOClient from 'socket.io-client';
import * as sailsIOClient from 'sails.io.js';

// Instantiate the socket client (`io`)
// (for now, you must explicitly pass in the socket.io client when using this library from Node.js)
const io = sailsIOClient(socketIOClient);
const isProductionEnvironment = !(process.env.NODE_ENV === 'development');
// Specify the host and port of the Sails backend
io.sails.url = window.location.origin;
// Configure the socket client
io.sails.autoConnect = false;
io.sails.reconnection = true;
io.sails.reconnectionAttempts = isProductionEnvironment ? 100 : 300;
io.sails.reconnectionDelay = isProductionEnvironment ? 10000 : 3000;
io.sails.reconnectionDelayMax = isProductionEnvironment ? 20000 : 3000;
io.sails.environment = isProductionEnvironment ? 'production' : 'development';
io.sails.headers = { 'x-csrf-token': window.CSRF };

const socket = io.sails.connect();

export default { socket };