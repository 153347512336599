import Vue from 'vue'
import Router from 'vue-router'
import SignIn from '../pages/auth/SignIn.vue'
import ResetPasswordRequest from '../pages/auth/ResetPasswordRequest.vue'

Vue.use(Router)

const routes = [
  { path: '/', name: 'signin', component: SignIn },
  { path: '/reset-password-request', name: 'reset-password-request', component: ResetPasswordRequest },
]

export default new Router({
  mode: 'history',
  hash: false,
  routes
})