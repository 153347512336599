var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vertical-center", attrs: { autocomplete: "on" } },
    [
      _c("transition", { attrs: { appear: "", name: "fade" } }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c(
                "form",
                {
                  staticClass: "form-centered",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.signIn.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("h3", { staticClass: "mb-3" }, [_vm._v("PLEASE SIGN IN")]),
                  _vm._v(" "),
                  _vm.message
                    ? _c("small", { class: [_vm.messageClass, "mb-3"] }, [
                        _vm._v(_vm._s(_vm.message.value))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.formIsPopulated
                    ? _c("small", { staticClass: "text-success mb-3" }, [
                        _vm._v("Please sign in to access your account")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-group mb-3" }, [
                    _c(
                      "label",
                      {
                        staticClass: "sr-only",
                        attrs: { for: "email_address" }
                      },
                      [_vm._v("Enter your email address")]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "input-group-text",
                        attrs: { id: "addon-email" }
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["far", "at"] }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.email_address,
                          expression: "form.email_address"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "email",
                        id: "email_address",
                        placeholder: "Enter your email address",
                        "aria-describedby": "addon-email",
                        autofocus: "",
                        disabled: _vm.formIsProcessing,
                        autocomplete: "username"
                      },
                      domProps: { value: _vm.form.email_address },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "email_address",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-group mb-3" }, [
                    _c(
                      "label",
                      { staticClass: "sr-only", attrs: { for: "password" } },
                      [_vm._v("Enter your password")]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "input-group-text",
                        attrs: { id: "addon-password" }
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["far", "key"] }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.password,
                          expression: "form.password"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "password",
                        id: "password",
                        placeholder: "Enter your password",
                        "aria-describedby": "addon-password",
                        disabled: _vm.formIsProcessing,
                        autocomplete: "current-password"
                      },
                      domProps: { value: _vm.form.password },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.form, "password", $event.target.value)
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-lg btn-block mb-3",
                      attrs: {
                        type: "submit",
                        id: "signIn",
                        disabled: !this.formIsPopulated || this.formIsProcessing
                      }
                    },
                    [
                      _vm._v("SIGN IN "),
                      _c("font-awesome-icon", {
                        staticClass: "ms-1",
                        attrs: {
                          icon: ["far", "sign-in-alt"],
                          "fixed-width": ""
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "small",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: { name: "reset-password-request" },
                            title: "Click to reset your user account password"
                          }
                        },
                        [_vm._v("Forgot your password?")]
                      )
                    ],
                    1
                  )
                ]
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }