//  ┌─┐┌─┐┬┬  ┌─┐  ┬┌─┐
//  └─┐├─┤││  └─┐  ││ │
//  └─┘┴ ┴┴┴─┘└─┘  ┴└─┘

// Require the sockets.js file if you want to be able to use the socket client to
// do things like `io.socket.get()` inside of this script.
import io from '../dependencies/websocket.js'

// To make the socket client available globally, uncomment the next line:
window.io = io

// Require the styles for this page.  This will cause the `projects.bundle.css` file
// to be generated.  Note that you can require multiple .less or .css files here, and
// they will _all_ be bundled together into `projects.bundle.css` (the name is based
// on the name of this JavaScript file).
import '/assets/styles/auth.scss'

//  ┌┐ ┌─┐┌─┐┌┬┐┌─┐┌┬┐┬─┐┌─┐┌─┐
//  ├┴┐│ ││ │ │ └─┐ │ ├┬┘├─┤├─┘
//  └─┘└─┘└─┘ ┴ └─┘ ┴ ┴└─┴ ┴┴

// styles loaded via sass
import bootstrap from 'bootstrap'

//  ┬  ┬┬ ┬┌─┐
//  └┐┌┘│ │├┤
//   └┘ └─┘└─┘

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import router from '../routes/auth.js'
import RouterComponent from '../components/RouterComponent.vue'
import Notification from '../mixins/Notification.vue'

Vue.config.productionTip = false;

//  ┌─┐┌─┐┌┐┌┌┬┐  ┌─┐┬ ┬┌─┐┌─┐┌─┐┌┬┐┌─┐
//  ├┤ │ ││││ │   ├─┤│││├┤ └─┐│ ││││├┤
//  └  └─┘┘└┘ ┴   ┴ ┴└┴┘└─┘└─┘└─┘┴ ┴└─┘

import { library } from '@fortawesome/fontawesome-svg-core'
import { faAt } from '@fortawesome/pro-regular-svg-icons/faAt'
import { faKey } from '@fortawesome/pro-regular-svg-icons/faKey'
import { faPaperPlane } from '@fortawesome/pro-regular-svg-icons/faPaperPlane'
import { faSignInAlt } from '@fortawesome/pro-regular-svg-icons/faSignInAlt'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(
  faAt,
  faKey,
  faSignInAlt,
  faPaperPlane
)
Vue.component('font-awesome-icon', FontAwesomeIcon)

//  ┬┌┐┌┬┌┬┐┬┌─┐┬  ┬┌─┐┌─┐  ┌─┐┌─┐┌─┐
//  │││││ │ │├─┤│  │└─┐├┤   ├─┤├─┘├─┘
//  ┴┘└┘┴ ┴ ┴┴ ┴┴─┘┴└─┘└─┘  ┴ ┴┴  ┴

const NotifyMixin = Vue.mixin(Notification)

/* eslint-disable no-new */
// new Vue({
//   el: '#app',
//   store,
//   render: h => h(SignIn)
// })

new Vue({
  el: '#app',
  mixins: [ NotifyMixin ],
  router,
  render: h => h(RouterComponent)
})